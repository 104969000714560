import Vue from 'vue'
import {
  Button, Tabbar, NavBar, TabbarItem, Form, Field, Popup, Cascader, Toast, Cell, List, Icon,
  Grid,
  Col, Row,
  GridItem,
  CellGroup, RadioGroup, Radio
  , Checkbox, CheckboxGroup
  , Swipe, SwipeItem
  , Lazyload
  , NoticeBar
  , Search
  , DatetimePicker
  , CountDown

} from 'vant'

Vue.use(CountDown)

Vue.use(DatetimePicker)

Vue.use(Search)
Vue.use(NoticeBar)
Vue.use(NavBar)

Vue.use(Lazyload)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Button)

Vue.use(Radio)
Vue.use(RadioGroup)

Vue.use(Grid)
Vue.use(GridItem)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(Form)
Vue.use(Field)
Vue.use(Cascader)
Vue.use(Tabbar)
Vue.use(NavBar)
Vue.use(TabbarItem)
Vue.use(Button)

Vue.use(Cell)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
